import { I18nextProvider } from "react-i18next"
import { PageContextProvider } from "./src/pageContext"
import React from "react"
import i18n from "./src/i18n/config"

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}

export const onRenderBody = (
  { setHeadComponents, setPostBodyComponents },
  pluginOptions
) => {
  setPostBodyComponents([
    <script
      key="addBeacon"
      dangerouslySetInnerHTML={{
        __html: `
        !(function(e, t, n) {
          function a() {
            var e = t.getElementsByTagName("script")[0],
              n = t.createElement("script");
            (n.type = "text/javascript"),
              (n.async = !0),
              (n.src = "https://beacon-v2.helpscout.net"),
              e.parentNode.insertBefore(n, e);
          }
          if (
            ((e.Beacon = n = function(t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
            (n.readyQueue = []),
            "complete" === t.readyState)
          )
            return a();
          e.attachEvent
            ? e.attachEvent("onload", a)
            : e.addEventListener("load", a, !1);
        })(window, document, window.Beacon || function() {});
      `,
      }}
    />,
    <script
      key="initBeacon"
      dangerouslySetInnerHTML={{
        __html: `
        window.Beacon("init", "5e1fb22e-f88a-4945-a929-7af8af67ab20");
      `,
      }}
    />,
  ])
}
